.drawer {
  position: relative;
  &__close-btn .custom-icon {
    cursor: pointer;
    position: absolute;
    top: var(--space-sm);
    right: var(--space-sm);
    margin: 0;
    padding: 0;
  }
}
