@import "src/styles/variables";

.schedule-visit-modal {
  &__timepicker {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: var(--space-md_base);
    &-separator {
      margin-top: var(--space-md_base);
    }
  }
}

