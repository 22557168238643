@import "src/styles/variables";

.services {
  &-divider.MuiDivider-root {
    margin: var(--space-sm) 0;
  }
  &-card {
    display: flex;
    column-gap: 28px;

    &__section {
      display: flex;
      flex-direction: column;
      row-gap: var(--space-xs);

      &-details {
        display: flex;
        column-gap: var(--space-sm);
      }
    }
  }
}
