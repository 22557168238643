@import "src/styles/variables";

.page-changer {
  display: flex;
  align-items: center;
  margin-right: var(--space-lg);
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  &__input.MuiFormControl-root {
    margin-right: var(--space-xs);
    background: $background-paper;
    .MuiInputBase-root {
      padding: var(--space-sm) var(--space-md_base);
      width: 48px;
      .MuiInputBase-input {
        padding: 0;
        text-align: center;
      }
    }
  }
}
