@import "src/styles/variables";

.search.MuiFormControl-root {
  .MuiOutlinedInput-root {
    border-radius: var(--space-xs);
    &:not(.Mui-focused):not(:hover) .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
  }
  .MuiOutlinedInput-input {
    padding: var(--space-sm) var(--space-md_base);
    padding-left: 0;
  }
}
