@import "src/styles/variables";

.reports-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 160px;
  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: var(--space-sm);
    &-text.MuiTypography-root {
      font-size: 13px;
      font-weight: $semi-bold;
    }
  }
  .custom-icon {
    color: $primary-main;
  }
}
