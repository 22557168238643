@import "src/styles/variables";

.select-base.MuiAutocomplete-root {
  .MuiOutlinedInput-root {
    border-radius: var(--space-xs);
    background: $background-paper;
    padding: var(--space-sm) var(--space-md_base);
    min-width: 88px;
    .MuiAutocomplete-input {
      padding: 0;
    }
    .MuiAutocomplete-endAdornment {
      top: auto;
      bottom: auto;
      right: var(--space-sm);
      stroke-width: 1.5;
      svg {
        color: $dark;
      }
    }
  }
}
