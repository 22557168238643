$background-color: #F9F9F9;
$background-paper: #ffffff;
$default-border: 1px solid #e7e7e7;
$header-height: 68px;
$navigation-width: 268px;
$custom-table-cell-width: 124px;
$margin-from-header: 130px;
$paper-container-top: 175px;
$stencil-width: 250px;
$inspector-width: 400px;
$modal-spacing: 24px;
$inspector-transition: .3s ease-in-out;
$navigator-xTranslate: -405px;
$primary-main: #3F51B5;
$primary-dark: #283593;
$tertiary-main: #00B0FF;
$success-color: #009688;
$error-color: #D32F2F;
$warning-color: #FFC107;
$badge-default-bg: rgba(79, 79, 79, 0.08);
$gray: #bbb;
$light-gray: rgba(79, 79, 79, 0.08);
$other-snackbar: #323232;
$dark: #0A0A0A;
$dark-secondary: rgba($dark, .73);
$dark-disabled: rgba($dark, .43);
$black: #000000;
$divider-gray: rgba($black, .12);
$other: #090909;
$outlined-border: rgba($other, .23);
$default-image-width: 300px;
$semi-bold: 600;

$paper-zIndex: 1;
$inspector-zIndex: 2;
$header-zIndex: 2;
$navigator-zIndex: 2;
$tools-container-zIndex: 2;
