@import "src/styles/spacing";

.need-revisit-modal {
  padding: 24px 0 0 0;

  .MuiFormControl-root {
    padding-bottom: var(--space-md_base);
  }
}

