@import "src/styles/variables";

.toolbar.MuiToolbar-root {
  border-bottom: 1px solid $divider-gray;
  padding: var(--space-md) var(--space-lg);
  position: sticky;
  top: 0;
  z-index: 1;
  background: $background-paper;
}
