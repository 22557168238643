@import "src/styles/variables";

.inspector-container {
  position: absolute;
  top: 185px;
  bottom: 10px;
  right: 0;
  width: $inspector-width;
  overflow: auto;
  z-index: $inspector-zIndex;
  pointer-events: none;
  background-color: white;
  border-radius: 4px;
  transition: $inspector-transition;
  display: flex;
  flex-direction: column;

  &.hidden {
    opacity: 0;
    transform: translateX($inspector-width);
  }

  &.visible {
    opacity: 1;
    pointer-events: auto;
    transform: translateX(-10px);
  }
}
