@import "src/styles/variables";

.header-section {
  margin-bottom: var(--space-lg);
  .gray-color {
    color: $dark-disabled;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: var(--space-xxs);
    white-space: nowrap;
  }
  &__title {
    display: flex;
    column-gap: var(--space-xs);
  }
  &__username.MuiTypography-root {
    margin: var(--space-md) 0;
  }
  &__info {
    display: flex;
    flex-direction: row;
    column-gap: 80px;
    &-status {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .ticket-details {
        min-width: unset;
      }
    }
  }
}
