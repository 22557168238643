@import "src/styles/variables";

.checklist-scenario {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .stepper {
    width: 100%;
    padding: var(--space-lg);
  }

  .header {
    height: 50px;
    width: 100%;
    margin-bottom: var(--space-lg);
    padding: 0 var(--space-md);
    -webkit-box-shadow: 0 5px 26px -10px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 5px 26px -10px rgba(0, 0, 0, 0.3);
    box-shadow: 0 5px 26px -10px rgba(0, 0, 0, 0.3);

    .scenario-name {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    p {
      margin-left: var(--space-sm);
    }
  }
}
