@import "src/styles/variables";

.pagination {
  .MuiOutlinedInput-root.select-base {
    order: -1;
    margin-right: var(--space-xs);
  }
  .MuiTablePaginationUnstyled {
    &-toolbar {
      display: flex;
      align-items: center;
    }
    &-selectLabel {
      font-size: var(--space-sm);
    }
    &-displayedRows {
      margin-left: auto;
      font-size: var(--space-sm);
    }
  }
}
