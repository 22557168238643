.check-approve {
  margin: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #C7FED3;
  box-shadow: 0 0 0 12px #ebfff3;
  //border: 8px solid #ebfff3;
}
