@import "src/styles/variables";

.checklist-scenario-modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .modal-header {
    position: relative;
    top: 0;
    display: flex;
    flex-direction: column;
    box-shadow: none;
    width: 100%;
    background-color: $background-paper;
    .title-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 14px 32px 14px 32px;
      .ticket-info {
        display: flex;
        h5 {
          margin-right: 10px;
        }
      }
    }
  }

  .progress-wrapper {
    display: flex;
    width: 100%;
    padding: 12px 32px;
    .text {
      margin-right: 32px;
    }
    .progress-bar {
      display: flex;
      align-items: center;
      width: 100%;
      .background-strap {
        position: relative;
        height: 8px;
        background-color: rgba(9, 9, 9, 0.09);
        width: 100%;
        border-radius: 100px;
        margin-right: 32px;
        overflow: hidden;
        .progress-strap {
          position: absolute;
          height: 8px;
          background-color: #3F51B5;
          width: 100%;
          border-radius: 100px;
          margin-right: 32px;
          transition: 0.5s;
          left: -100%;
        }
      }
    }
  }
  .steps-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .start-step {
      width: 442px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h4 {
        font-size: 24px;
        font-weight: 600;
        margin-top: 24px;
        margin-bottom: 16px;
        text-align: center;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 24px;
        text-align: center;
      }
    }

    .input-step, .message-step, .select-carousel-step, .select-list-step, .end-step {
      width: 443px;
      display: flex;
      flex-direction: column;
      align-items: center;
     select, input, .select-base, .text-field-base {
       width: 100%;
     }
    }
    .input-step, .select-carousel-step, .select-list-step,  {
      h5 {
        margin-bottom: 24px;
      }
    };

    .message-step {
      hr {
        margin: 16px 0;
      }
    }

    .select-carousel-step {
      .carousel-item {
        margin: auto;
      }
    }

    .end-step {
      img {
        margin-bottom: 24px;
        height: 54px;
        width: 54px;
      }
      h4 {
        margin-bottom: 16px;
      }
      p {
        margin-bottom: 24px;
        text-align: center;
      }
      .buttons-wrapper {
       button {
         margin: 0 12px;
       }
      }
    }

    .footer {
      width: 100%;
      padding: 16px;
      position: absolute;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .button-next {
        margin-left: 8px;
      }
      hr {
        transform: translateY(-16px);
      }
      .buttons-wrapper {
        display: flex;
        justify-content: flex-end;
        width: 443px;
      }
    }
  }
}
