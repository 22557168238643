@import "src/styles/variables";

.tickets {
  &__tabs {
    border-bottom: 2px solid $divider-gray;
    margin: var(--space-sm) var(--space-lg) 0;
    &-indicator.MuiTabs-indicator {
      height: var(--space-xxs);
      border-radius: var(--space-xs);
    }
    .MuiTypography-root {
      color: $dark;
      font-size: 15px;
      font-weight: $semi-bold;
      margin-right: var(--space-xs);
    }
    .MuiButtonBase-root {
      min-width: 160px;
    }
    &-tab {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .table {
    padding-top: $modal-spacing;
  }
  .phone {
    color: $primary-main;
    text-decoration: unset;
  }
}

