@import "src/styles/variables";

.scenarios-management {
  background-color: #efefef;
  padding:  var(--space-md);
  display: flex;
  flex-direction: column;
  height: 100%;

  .card-container {
    margin-top: var(--space-sm);
    padding:  var(--space-sm);
    padding-bottom: var(--space-lg);
    max-height: calc(100% - 80px);
    position: relative;
    min-height: 400px;
  }

  .MuiPaper-root > .delete-button {
    position: absolute;
    right: var(--space-md_base);
    bottom: var(--space-md_base);
  }
}

.modal-add-new-scenario {
  width: 400px;
  height: 300px;
  position: relative;

  h6 {
    text-align: center;
    margin-bottom: var(--space-md_base);
  }

  .MuiFormControl-root {
    width: 100%;
  }

  p {
    font-size: 12px;
  }

  button {
    position: absolute;
    right: var(--space-sm);
    bottom: var(--space-sm);
  }
}

.invalid-scenario {
  color: $gray;
}

