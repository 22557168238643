:root {
  --space-unit: 8px;
  --space-xxxs: calc(0.25 * var(--space-unit));
  --space-xxs: calc(0.5 * var(--space-unit)); // 4px
  --space-xs: calc(1 * var(--space-unit)); // 8px
  --space-sm: calc(1.5 * var(--space-unit)); // 12px
  --space-md_base: calc(2 * var(--space-unit)); // 16px
  --space-md: calc(2.5 * var(--space-unit)); // 20px
  --space-lg: calc(4 * var(--space-unit)); // 32px
  --space-xl: calc(6.5 * var(--space-unit)); // 52px
}
