@import "styles/variables";
@import "styles/shared";
@import "styles/material-overrides";
@import "src/styles/spacing";
@import "src/styles/rappid-overrides";

.app {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
