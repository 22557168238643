@import "src/styles/variables";
@import "node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg";

.title {
  font-weight: bold;
  font-size: 18px;
}

.row-flex {
  display: flex;
  justify-content: space-between;
  padding: var(--space-sm) var(--space-md);
  align-items: center;
}

.properties-container {
  padding: var(--space-md);
  flex-grow: 1;
  border: $default-border;
  border-width: 1px 0 1px 0;
  overflow-y: auto;
}

.field {
  padding-bottom: var(--space-md);
}

.field-label {
  font-weight: bold;
}

.editor-wrapper {
  margin-top: var(--space-sm);
  display: flex;
  flex-direction: column-reverse;
  border: $default-border;
  border-radius: 5px;
}

.inspector-container .save-btn {
  margin: var(--space-sm) var(--space-md);
  text-transform: uppercase;
  align-self: flex-end;
}

.rdw-link-modal {
  left: -105px;
}

.rdw-image-modal {
  left: -150px;
}

.rdw-embedded-modal {
  left: unset;
  right: 0;
}

.editor-textbox {
  padding: 0 var(--space-sm);
}

.textbox-options {
  margin-bottom: 0;
  border-width: 1px 0 0 0;
  background-color: $background-color;
}

.rdw-option-wrapper {
  border: $default-border;
  height: 30px;
  width: 30px;
  background: $background-color;
}

.rdw-option-active {
  background: #e2e4ea;
}

figure {
  display: flex;
  justify-content: center;

  img {
    max-width: $default-image-width;
    height: auto;
    object-fit: cover;
  }
}
