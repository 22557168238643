@import "src/styles/shared";

.drawer-wrapper {
  padding: 20px 32px;
  .patient-name {
    margin: 20px;
  }

  h5 {
    font-size: 20px;
  }
  .care-grade {
    padding: 20px 0px 32px 0px;
    h6 {
      margin-bottom: 6px;
    }
    p {
      font-size: 14px;
    }
  }
  .buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
}
