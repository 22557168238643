@import "src/styles/variables";

.base-modal {
  position: absolute;
  padding: $modal-spacing;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: var(--space-xs);
  background: $background-paper;
  outline: none;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: var(--space-xl);
    margin-bottom: var(--space-lg);
    .custom-icon {
      cursor: pointer;
    }
  }

  .MuiDivider-root {
    width: 100%;
  }
}

.modal-full-screen {
  top: 0;
  left: 0;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
  border-radius: 0;
  padding: 0;
}
