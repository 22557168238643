@import "src/styles/variables";

.disapprove-report-modal {
  row-gap: $modal-spacing;
  &__memo {
    display: flex;
    flex-direction: column;
  }
}

