@import "src/styles/variables";

.memos {
  &__list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: var(--space-md_base);
    &-item {
      display: flex;
      flex-direction: column;
      row-gap: var(--space-xs);
      &-header {
        display: flex;
        column-gap: var(--space-sm);
      }
      &-buttons {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .dot {
          background-color: $dark-disabled;
          margin: var(--space-sm);
        }
        .MuiButton-root {
          padding: 0;
          min-width: unset;
        }
      }
    }
    &.multiple-memos {
      margin-bottom: var(--space-md_base);
    }
  }
}
