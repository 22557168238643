@import "src/styles/variables";

.table {
  padding: var(--space-lg);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .MuiTableContainer-root {
    margin-bottom: var(--space-md_base);
  }
  &__head .MuiTableCell-head {
    color: $dark-secondary;
  }
  &__body {
    background: $background-paper;
    .MuiTableCell-body {
      font-size: var(--space-sm);
    }
  }
  .MuiTableCell-root.custom-width {
    min-width: $custom-table-cell-width;
  }
}
