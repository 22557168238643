@import "src/styles/variables";

.list-item {
  display: flex;
  align-items: center;
  color: $dark-secondary;
  &__icon {
    width: var(--space-md);
    height: var(--space-md);
    margin-right: var(--space-md_base);
    svg {
      width: inherit;
      height: inherit;
    }
  }
  &-inset {
    padding-left: var(--space-lg);
    p {
      margin-left: var(--space-xxs);
    }
  }
  &-selected {
    color: $dark;
  }
}
