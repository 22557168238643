@import "src/styles/variables";

.text-field-base .MuiOutlinedInput-root {
  border-radius: var(--space-xs);
  background: $background-paper;
  color: $dark-secondary;
  padding: var(--space-sm) var(--space-md_base);
  min-width: 88px;
  .MuiOutlinedInput-input {
    padding: 0;
  }
}
