@import "src/styles/variables";

.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $warning-color;
  height: 24px;
  width: 28px;
  border-radius: 100px;
  font-size: 11px;
  font-weight: 500;
  color: $black;
}
