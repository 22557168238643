@import "src/styles/variables";

.dot {
  background-color: $dark;
  width: var(--space-xxs);
  height: var(--space-xxs);
  border-radius: 50%;
  margin: 0 24px;
}

.text-decoration-unset {
  text-decoration: unset;
}

.cursor-pointer {
  cursor: pointer;
}

.color-dark {
  color: $dark;
}

.color-dark-secondary {
  color: $dark-secondary;
}

.color-dark-disabled {
  color: $dark-disabled;
}

.avatar {
  border-radius: 50%;
  width: var(--space-lg);
  height: var(--space-lg);
}

.modal {
  display: flex;
  flex-direction: column;
  &__btn-group {
    display: flex;
    justify-content: flex-end;
    column-gap: var(--space-md_base);
    button {
      border-radius: var(--space-xs);
    }
    &.column-align {
      flex-direction: column;
      width: 100%;
    }
  }
  &.visit-modal {
    min-width: 400px;
    max-width: 512px;
    row-gap: $modal-spacing;
  }
}
