@import "src/styles/variables";

.reports {
  display: flex;
  flex-direction: column;
  margin: $modal-spacing 0;
  &-title.MuiTypography-root {
    margin-bottom: var(--space-sm);
  }
}
