@import "src/styles/variables";

.scenario-builder {
  flex-grow: 1;
  width: 100%;
  background-color: $background-color;

  .paper-container {
    position: absolute;
    top: $paper-container-top;
    bottom: 0;
    right: 0;
    overflow: hidden;
    left: $stencil-width;
    z-index: $paper-zIndex;

    .joint-paper-scroller {
      overflow: hidden;
    }
  }

  .stencil-container {
    position: absolute;
    left: 0;
    top: $margin-from-header;
    bottom: 0;
    width: $stencil-width;
    border-top: $default-border;
    border-right: $default-border;
    background-color: white;

    .stencil-header {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
    }

    .stencil-title {
      margin-left: var(--space-md);
      display: flex;
      justify-content: center;
      font-weight: bold;
    }

    .joint-stencil.joint-theme-default {
      top: 60px;
      border: none;
    }
  }

  .joint-toolbar {
    width: 100%;
    justify-content: flex-end;

    .joint-toolbar-group .joint-widget {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: var(--space-xxxs);
      background-color: #ffffff;
      padding: 0;
    }
  }

  .tools-container {
    position: absolute;
    top: $margin-from-header;
    right: 0;
    z-index: $tools-container-zIndex;

    .joint-toolbar {
      background-color: transparent;
      border: none;
    }
  }

  .navigator-container {
    position: absolute;
    bottom: 0;
    right: 10px;
    z-index: $navigator-zIndex;
    transform: translateX(0);
    transition: transform $inspector-transition;

    &.translated {
      transform: translateX($navigator-xTranslate);
    }

    .joint-navigator.joint-theme-default {
      border: $default-border;
    }
  }

  .joint-widget.joint-theme-modern[data-type="redo"], .joint-widget.joint-theme-modern[data-type="undo"] {
    border: 1px solid #6b6c8a;

    &:disabled {
      opacity: 0.6;
    }

    &:not(:disabled):hover {
      background-color: #6b6c8a;
      color: #ffffff;
    }
  }

  .joint-widget.joint-theme-modern[data-type="redo"]:after, .joint-widget.joint-theme-modern[data-type="undo"]:after, .joint-widget.joint-theme-modern[data-name="tree"]:after {
    font-family: "Material Icons", sans-serif;
    width: unset;
    height: unset;
    font-size: 20px;
    background-image: none;
  }

  .joint-widget.joint-theme-modern[data-type="redo"]:after {
    content: "redo";
  }

  .joint-widget.joint-theme-modern[data-type="undo"]:after {
    content: "undo";
  }

  .joint-widget.joint-theme-modern[data-name="tree"]:after {
    content:"schema";
  }
}

.header-tools {
  padding: 0 var(--space-xs);
  display: flex;
  justify-content: space-around;

  .upload-btn,
  .download-btn {
    min-width: 30px;
    width: 35px;
    margin: 0 10px 0 0;
  }

  #upload-file-input {
    display: none;
  }
}
