@import "src/styles/spacing";

.approve-report-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 360px;
  padding: 24px 0;

  h4{
    margin-bottom: var(--space-xs);
  }

  p {
    margin-bottom: 48px;
    text-align: center;
  }
  .modal__btn-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    button {
      margin-bottom: var(--space-xs);
    }
  }
}
