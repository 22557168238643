@import "src/styles/variables";

.end-visit-modal {
  &__memo {
    display: flex;
    flex-direction: column;
  }
  &__pickers {
    display: flex;
    column-gap: $modal-spacing;
  }
}
