.select-option-container {

  h3 {
    margin: var(--space-sm) 0;
  }
  .MuiNativeSelect-select {
    padding: var(--space-sm) var(--space-lg) var(--space-sm) var(--space-sm);
  }
  .MuiCard-root {
    padding: var(--space-sm);
    margin: var(--space-sm) 0;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .delete-button {
      position: absolute;
      right: 5px;
      bottom: 5px;
    }
    .upload-button {
      margin: var(--space-xxs);
    }
  }
  .MuiInputBase-input {
    padding: var(--space-xxs);
    width: 120px;
  }
  .MuiInputBase-root {
    margin: 0 var(--space-xxs) var(--space-xxs);
  }

  .image-container {
    width: 120px;
    height: 120px;
    img {
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      height: 100%;
      margin: 0 var(--space-xxs);
    }
  }

  .MuiTextField-root {
    label {
    font-size: 0.75em;
      line-height: 1em;
    }
  }
}
