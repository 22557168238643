@import "src/styles/variables";

.select-base.MuiOutlinedInput-root {
  border-radius: var(--space-xs);
  background: $background-paper;
  padding: var(--space-sm) var(--space-md_base);
  min-width: 88px;
  .MuiSelect-select {
    padding: 0;
  }
  .MuiSelect-icon {
    top: auto;
    bottom: auto;
    right: var(--space-sm);
    stroke-width: 1.5;
    color: $dark;
  }
  .render-value {
    color: $dark-disabled;
  }
}
