@import "src/styles/variables";
@import "src/styles/shared";

.services {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--space-sm);
    &-grade {
      background: $light-gray;
      padding: var(--space-xs) var(--space-sm);
      border-radius: var(--space-xs);
    }
  }
}
