@import "src/styles/variables";

.layout {
  display: flex;
  height: 100%;
  &__content {
    position: relative;
    width: calc(100% - #{$navigation-width});
    background: $background-color;
    &-body {
      height: calc(100% - #{$header-height});
      overflow-y: auto;
    }
  }
}
