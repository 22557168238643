@import "src/styles/variables";
@import "src/styles/shared";

.patient-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: var(--space-md) 0;
  &__contact {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-xs);
    &-phone {
      color: $primary-main;
    }
    &-email {
      color: $primary-dark;
    }
    &-address {
      color: $dark-secondary;
    }
  }
}
