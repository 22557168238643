@import "src/styles/variables";
@import "src/styles/shared";

.memos {
  margin-top: $modal-spacing;
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--space-sm);
    &-sort {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: var(--space-xs);
    }
  }
  &__add {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: var(--space-md_base);
    margin-bottom: var(--space-md_base);
  }
}
