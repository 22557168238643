.alert-triangle {
  margin: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #FEF0C7;
  box-shadow: 0 0 0 12px #FFFAEB;
  //border: 8px solid #ebfff3;
  path, line {
    color: #FF8F00;
  }
}
