@import "node_modules/react-responsive-carousel/lib/styles/carousel";

.carousel-item {
  max-width: 250px;

  img {
    max-width: 200px;
    margin: 0 auto;
    padding: var(--space-lg);
  }
}
