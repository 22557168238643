@import "src/styles/variables";

.assignment-services-modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .modal-header {
    position: relative;
    top: 0;
    display: flex;
    flex-direction: column;
    box-shadow: none;
    width: 100%;
    background-color: $background-paper;
    .title-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      padding: 14px 32px 14px 32px;
      h5 {
        line-height: 32px;
      }
    }
  }

  .body-wrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
    max-height: calc(100% - 68px);
    .report-wrapper {
      width: 50%;
      height: 100%;
      max-height: calc(100% - 68px);
      .report-header {
        display: flex;
        justify-content: space-between;
        padding: 12px 36px;
        border-bottom: 1px solid rgba(9,9,9,0.12);
        .icon-button {
          p {
            font-weight: 600;
            font-size: 13px;
            color: #0A0A0A !important;
            line-height: 24px;
          }
        }
        .date-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          p {
            color: rgba(10, 10, 10, 0.43);
            font-size: 14px;
          }
          .dot {
            width: 4px;
            height: 4px;
            border-radius: 4px;
            background-color: rgba(10, 10, 10, 0.43);
          }
        }
      }
      .report-preview {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        background-color: #F9F9F9;
        .image-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            margin: 0 auto;
          }
          h5{
            color: rgba(10, 10, 10, 0.43);
          }
        }

      }
    }
    .info-wrapper {
      width: 50%;
      max-height: 100%;
      border-left: 1px solid rgba(9,9,9,0.12);
      padding: 24px 32px;
      overflow-y: auto;
      .patient-info-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;

        .name {
          font-weight: 600;
          font-size: 14px;
          margin-bottom: 4px;
        }
        .primary-color {
          color: $primary-main;
          margin-bottom: 4px;
        }
        .secondary-color {
          color: $dark-secondary;
          margin-bottom: 4px;
        }
      }
      .select-care-grade {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
        .select-base {
          width: 60%;
        }
      }
      .service-item {
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        .service-item__header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          svg {
            cursor: pointer;
          }
        }
        .select-service {
          width: 60%;
          margin-left: auto;
          p {
            margin-bottom: 8px;
          }
          .select-base {
            width: 100%;
            margin-bottom: 20px;
          }
        }
      }
      .add-service-button {
        margin-top: 20px;
        font-size: 14px;
      }
    }
  }
}
