@import "src/styles/variables";

.date-time {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $dark-disabled;
  .dot {
    margin: 0 var(--space-xs);
    background-color: $dark-disabled;
  }
  &__divider.MuiTypography-root {
    margin: 0 var(--space-xxs);
  }
}
