@import "variables";

.joint-dialog.joint-flash-message.joint-theme-modern {
  .body {
    width: 92%;
    margin-left: 8%;
    position: relative;

    &::before {
      font-family: "Material Icons", sans-serif;
      position: absolute;
      left: -25px;
      font-size: 20px;
      color: black;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &[data-type="alert"] .btn-close,
  &[data-type="warning"] .btn-close,
  &[data-type="success"] .btn-close {
    color: rgba(0, 0, 0, 0.91);
  }

  &[data-type="warning"] {
    .body::before {
      color: $warning-color;
      content: "warning_amber"
    }
  }

  &[data-type="success"] {
    .body::before {
      color: $success-color;
      content: "check_circle_outline"
    }
  }

  &[data-type="error"] {
    .body::before {
      color: $error-color;
      content: "report_gmailerrorred"
    }
  }
}
