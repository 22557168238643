@import "src/styles/variables";
@import "src/styles/shared";

.actions-selection {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 var(--space-md_base);

  .action-button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
