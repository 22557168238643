.patient-info__wrapper {
  display: flex;
  flex-direction: column;
  .row {
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;

    &-title {
      width: 60%
    }
    &-data__container {
      width: 40%;
      .data {
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;
        .key {
          font-size: 12px;
          line-height: 24px;
          font-weight: 600;
          margin-bottom: 4px;
        }
        .value {
          font-size: 14px;
          line-height: 20px;
          color: #0A0A0A;
          font-weight: 400;
          &--blue {
            color: #283593;
          }
        }
      }
    }
  }
}
