@import "src/styles/variables";

.header {
  height: var(--space-xl);
  width: 100%;
  background: $background-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: $header-zIndex;
  -webkit-box-shadow: 0 5px 26px -10px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 5px 26px -10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 5px 26px -10px rgba(0, 0, 0, 0.3);

  .logo {
    padding-left: var(--space-md);
    font-weight: bold;
  }

  .header-tools {
    padding: 0 var(--space-xs);
    display: flex;
    justify-content: space-around;

    p {
      margin: auto var(--space-md);
    }
  }
}
