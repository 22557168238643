@import "src/styles/variables";

.table-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: var(--space-lg);
  &__section {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: var(--space-md);
    color: $dark;
  }
}
