@import "src/styles/variables";

.navigation {
  min-width: $navigation-width;
  border-right: 1px solid $divider-gray;
  &__image {
    width: inherit;
    height: 101px;
    padding: var(--space-md_base);
    img {
      height: 45px;
    }
  }
}
