@import "src/styles/variables";

.ticket-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 300px;
  column-gap: var(--space-xs);
  &__label {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: var(--space-xxs);
    padding: var(--space-xs) var(--space-sm);
    border-radius: var(--space-xs);
    background: $badge-default-bg;
  }
  &__indicator {
    min-width: 76px;
  }
  &__square {
    margin-right: var(--space-xxs);
    height: var(--space-xs);
    width: var(--space-xs);
    border-radius: 2px;
  }
}

