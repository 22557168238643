@import "src/styles/variables";

.approve-report-modal {
  max-width: 360px;
  padding: $modal-spacing 0;
  h4 {
    margin: var(--space-xs);
  }
  h6 {
    margin-bottom: 48px;
    text-align: center;
  }
}

